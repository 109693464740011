* {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.bold {
  font-weight: bold !important;
}
button:hover {
  filter: none !important;
}
body:has(.p_login) {
  .toast {
    display: none;
  }
}
