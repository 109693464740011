@mixin mobile {
  @media screen and (width <= 428px) {  // iPhone 12 Pro Max
    @content;
  }
}
@mixin tablet {
  @media screen and (width <= 1024px) {
    @content;
  }
}
