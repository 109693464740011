.cdk-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 13;
  backdrop-filter: blur(4px);
  /* stylelint-disable */
  -webkit-backdrop-filter: blur(4px);
  /* stylelint-enable */
  // Match `transition()` in modal component class animation decorator
  transition: all 0.3s ease; // original: 0.09s
  &:has(.leave) {
    backdrop-filter: none;
    /* stylelint-disable */
    -webkit-backdrop-filter: none;
    /* stylelint-enable */
  }
  .cdk-overlay-backdrop {
    position: absolute;
    inset: 0;
    background-color: #000000;
    opacity: 0.5 !important;
    pointer-events: auto;
    // Match `transition()` in modal component class animation decorator
    transition: all 0.3s ease; // original: 0.09s
    &.leave {
      opacity: 0 !important;
    }
  }
  &:empty {
    display: none
  }
  .cdk-global-overlay-wrapper {
    position: absolute;
    inset: 0;
    display: flex;
    pointer-events: none;
  }
  .cdk-overlay-pane {
    position: relative;
    pointer-events: auto;
    &:has(.cdk-dialog-container) {
      width: 97%;
      max-width: 555px;
    }
  }
  .cdk-overlay-connected-position-bounding-box {
    position: absolute;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px;
  }
}
