#game-search {
  border: none;
  border-radius: 31px;
}
// ここは Angular Material Tabs 専用の CSS という扱いにしてもいいかもしれない（超長期的には使わない前提ではあるが）
mat-tab-header {
  position: sticky !important;
  top: -13px;
  background-color: var(--color-background);
  z-index: 2;
  .mat-mdc-tab-label-container {
    --mdc-secondary-navigation-tab-container-height: 41px;
    border-bottom: solid 0.7px #dadada !important;
    .mat-mdc-tab-labels {
      .mat-mdc-tab.mdc-tab--active .mdc-tab__ripple::before {
        background-color: transparent !important;
      }
      .mdc-tab__text-label {
        font-family: var(--font-family) !important;
      }
      .mat-mdc-tab.mdc-tab--active {
        .mdc-tab__text-label {
          color: var(--color-text) !important;
        }
      }
      .mdc-tab-indicator__content--underline {
        border-width: 3px !important;
        border-color: var(--color-primary) !important;
      }
    }
  }
}
