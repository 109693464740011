@import "./mixins";
:root {
  --color-text: #747474;
  --color-text-gray: #929292;
  --color-gray: #dfdfdf;
  --color-text-white: #f2f2f2;
  --color-text-white-hover: #e3e3e3;
  --color-background: #f9f2e9;
  --color-background-data: #fdf8f6;
  --color-primary: #ffa387;
  --color-link: #de894c;
  --color-success-green: #99c689;
  --color-invalid: #ff9ca6;
  --color-gradient-main: linear-gradient(170deg, #faeedf 1.6%, #eadbf6 99.15%);
  --color-input-focus-border: #e9b18d;
  --color-input-focus-shadow: 0 0 0 0.19rem rgba(237, 120, 45, 0.37);
  --width-max-base: 1188px;
  --width-scrollbar: 16px;
  --width-max-screen: calc(var(--width-max-base) - var(--width-scrollbar));
  --height-without-bottomnavi: calc(100svh - 84px);
  --font-family: "Custom Noto Sans", "Open Sans", helvetica, "Lucida Sans Unicode", "Lucida Grande", arial, verdana, sans-serif;
  --box-shadow: 1px 3px 5px rgba(25, 25, 28, 0.666);
  --box-shadow-float: 1.3px 1.7px 5px rgba(80, 73, 78, 0.349);
  --focus-visible-outline: solid 2px #e9b18d;
}
* {
  box-sizing: border-box;
  overflow-wrap: break-word;
}
html, body {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-family: var(--font-family);
  color: var(--color-text);
  background-color: var(--color-background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include mobile {
    font-size: 16px;
  }
}
app-root {
  transition: filter 0.03s ease-out;
}
p {
  margin-top: 0;
  margin-bottom: 1.3em;
  line-height: 1.5;
}
p a,
span a,
li a,
table a {
  color: var(--color-link);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.document {
  h2, h3, h4, h5, h6 {
    margin-top: 1.65em;
    margin-bottom: 0.91em;
  }
  h2 {
    font-size: 1.53em;
  }
  h3 {
    font-size: 1.23em;
  }
  h4 {
    font-size: 1.1em;
  }
  h5 {
    font-size: 1.09em;
  }
  h6 {
    font-size: 1.05em;
  }
  ul {
    margin: 1.3em auto;
    padding-left: 2.3em;
    li {
      list-style: disc;
      margin: 0.3em auto;
    }
  }
}
img {
  vertical-align: middle;
}
*:focus-visible {
  outline: var(--focus-visible-outline);
}
button {
  position: relative;
  cursor: pointer;
  &.reset {
    margin: 0;
    padding: 0;
    color: unset;
    font: unset;
    border: none;
    background: none;
    appearance: none;
  }
  // For BaseButtonComponent with icons
  fa-icon {
    position: static;
    transform: translateY(0.5px);
  }
}
fa-icon {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.skeleton {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  background-color: #e3e3e3;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image:
      linear-gradient(
        90deg,
        rgba(#f1f1f1, 0) 0,
        rgba(#f1f1f1, 0.2) 20%,
        rgba(#f1f1f1, 0.5) 60%,
        rgba(#f1f1f1, 0)
      );
    transform: translateX(-200%);
    animation: skeleton 1.3s infinite;
  }
}
.display_none {
  display: none;
}
@include mobile {
  .display_none_mobile {
    display: none;
  }
}
// ::-webkit-scrollbar {
//   width: var(--width-scrollbar);
// }
// ::-webkit-scrollbar-thumb {
//   border: solid 3.5px rgba(0, 0, 0, 0);
//   background-clip: padding-box;
//   border-radius: 9999px;
//   background-color: #58575e;
// }
