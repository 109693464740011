@font-face {
  font-family: "Custom Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local("NotoSansCJKjp-Regular.otf"),
    local("NotoSansJP-Regular.otf"),
    url("/assets/fonts/Noto_Sans_JP/NotoSansJP-Regular.woff2") format("woff2");
  // unicode-range: U+000-5FF;
}
@font-face {
  font-family: "Custom Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local("NotoSansCJKjp-Bold.otf"),
    local("NotoSansJP-Bold.otf"),
    url("/assets/fonts/Noto_Sans_JP/NotoSansJP-Bold.woff2") format("woff2");
  // unicode-range: U+000-5FF;
}
