.toast {
  position: fixed;
  bottom: 6.9em;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  min-width: 325px;
  min-height: 1em;
  padding: 0.8em 1.5em 0.91em;
  font-size: 0.93rem;
  color: var(--color-text-white);
  border-radius: 5px;
  background-color: #474747;
  box-shadow: 1px 1px 3px 0px rgb(67 67 67 / 67%);
  opacity: 0.5;
  transition: all 0.19s ease;
  z-index: 1003;
  &.show {
    opacity: 1;
    transform: translateX(-50%) translateY(0%);
  }
  &.hide {
    opacity: 0;
    transform: translateX(-50%) translateY(100%);
    transition: all 0.13s ease-in-out;
  }
}
