@import "./mixins";

/* .xxx  { Custom class applied here by `panelClass` */
.cdk-dialog-container {
  .modal_body {
    position: relative;
    margin: auto;
    padding: 0.9em 1.3em 0.5em;
    color: var(--color-text);
    background-color: #ffffff;
    box-shadow: var(--box-shadow);
    border-radius: 7px;
    button.absolute {
      position: absolute;
      top: 1.9em;
      right: 2.3em;
      bottom: auto;
      color: var(--color-text-gray);
      @include mobile {
        top: 1.2em;
        right: 1.25em;
      }
    }
    .title {
      margin-bottom: 1em;
      font-size: 1.05em;
      font-weight: bold;
    }
    .content {
      margin: 0.3em auto 0.9em;
      font-size: 0.93em;
      &.simple {}
      &.confirm {
        + .ask_again {
          margin: 1em auto 1em 0.5em;
          font-size: 0.93em;
        }
      }
      &.action {
        display: flex;
        flex-direction: column;
        gap: 17px;
      }
    }
    .buttons {
      padding-top: 5px;
      font-size: 0.9em;
      text-align: right;
      border-top: 1px solid #515254;
      button {
        margin: 0.3em 0em 0.3em 0.7em;
        padding: 0.39em 1.5em 0.4em;
      }
    }
  }
  &:focus-visible {
    outline: none;
  }
}
